import React from "react";

import Header from "../components/general/Header/Header";
import Footer from "../components/general/Footer/Footer";
import Banner from "../components/general/Banner/Banner";

import video from "../assets/videos/bannerVideoCompressed3.mp4";

import CustomHelmet from "../components/general/CustomHelmet/CustomHelmet";
import JoinPresentation from "../components/join/JoinPresentation/JoinPresentation";

export default () => (
  <div>
    <CustomHelmet
      title="Platoon DJs - Gå med"
      description="Bli medlem i vår förening"
      keywords="dj, stockholm, förening, medlem, kollektiv"
      canonical=""
    />
    <Header />
    <Banner fullHeight={false} videoUrl={video} tagline="" />
    <JoinPresentation />
    <Footer />
  </div>
);
