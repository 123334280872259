import React from "react";
import { Element } from "react-scroll";

import * as Styles from "./Signup.styles";

const ScrollElement = Element;

/* const chapters = [
  { label: "Arkitektursektionen (A)", value: "A" },
  { label: "Bergssektionen (B)", value: "B" },
  { label: "Datasektionen (D)", value: "D" },
  { label: "Civilingenjör & Lärare (CL)", value: "CL" },
  { label: "Doktorandsektion (Dr)", value: "DR" },
  { label: "Elektrosektionen (E)", value: "E" },
  { label: "Energi & Miljö (W)", value: "W" },
  { label: "Flygsektionen (T)", value: "T" },
  { label: "Fysiksektionen (F)", value: "F" },
  { label: "Industriell Ekonomi (I)", value: "I" },
  { label: "Informations-och Nanoteknik (IN)", value: "IN" },
  { label: "Ingenjörsektionen Bygg (IsB)", value: "ISB" },
  { label: "Ingenjörsektionen Flemingsberg (IsF)", value: "ISF" },
  { label: "Kemisektionen (K)", value: "K" },
  { label: "Maskinsektionen (M)", value: "M" },
  { label: "Medicinteknik (MiT)", value: "MIT" },
  { label: "Medieteknik (Media)", value: "MEDIA" },
  { label: "Samhällsbyggnadssektionen (S)", value: "S" },
  { label: "Telge teknologsektion (TT)", value: "TT" },
  { label: "Öppen Ingång (OPEN)", value: "OPEN" },
  { label: "Annan", value: "OTHER" },
];*/

export const Signup = () => {
  return (
    <Styles.Container>
      <ScrollElement name="signup" />
      <Styles.H1>Ansök nu</Styles.H1>
      <Styles.P>
        Klicka på länken nedan. Fyll i dina kontaktuppgifter så återkommer vi så
        snart som möjligt.
      </Styles.P>

      {/* Sök-knappen */}
      <Styles.SubmitButton
        as="a"
        href="https://docs.google.com/forms/d/1wVVkWfPm4Fh-U5PvYUnKOnf0a55Zt49QmGe0tl8IigY"
      >
        <span>Sök</span>
      </Styles.SubmitButton>

      <Styles.GrecaptchaTerms>
        Denna sida skyddas reCAPTCHA och Googles{" "}
        <a href="https://policies.google.com/privacy">integritetspolicy</a> och{" "}
        <a href="https://policies.google.com/terms">användarvillkor</a>{" "}
        tillämpas.
      </Styles.GrecaptchaTerms>
    </Styles.Container>
  );
};

export default Signup;
