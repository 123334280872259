import styled from "styled-components";
import { Color } from "../../../styles";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 64px 0 72px 0;
  box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.1);
  clip-path: polygon(0 0, 100% 48px, 100% calc(100% - 48px), 0 100%);
  color: rgba(0, 0, 0, 0.9);
`;

export const Columns = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 912px;
  margin: 48px auto 96px auto;

  @media only screen and (max-width: 960px) {
    width: auto;
    margin: 0 24px;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const ImageColumn = styled.div`
  flex: 0 1 300px;

  > .gatsby-image-wrapper {
    height: auto;
    border-radius: 50%;
  }
`;

export const TextColumn = styled.div`
  flex: 1 1 300px;
  margin-left: 120px;

  @media only screen and (max-width: 960px) {
    margin: 48px 0;
    flex: 1 1 100%;
    width: 100%;
  }

  > p {
    line-height: 160%;
    margin: 24px 0;
  }
`;

export const H1 = styled.h1`
  width: max-content;
  font-size: 3rem;
  letterspacing: 0.3rem;
  font-weight: 600;

  @media only screen and (max-width: 960px) {
    width: auto;
    line-height: 130%;
  }
`;

export const SignupButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 48px;
  border-radius: 24px;
  margin: 48px 24px 24px 24px;
  background-color: ${Color.GREEN_PRIMARY};

  color: ${Color.TEXT_WHITE_PRIMARY};
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;

  &:hover {
    background-color: ${Color.GREEN_LIGHT};
    cursor: pointer;
  }
`;

export const FullWideColumn = styled.div`
  max-width: 772px;
  margin: 48px auto 96px auto;

  > p {
    line-height: 160%;
    margin: 24px 0;
  }

  > iframe {
    margin-top: 48px;
  }

  @media only screen and (max-width: 960px) {
    width: auto;
    margin: 0 24px;
  }
`;
