import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { scroller } from "react-scroll";

import * as Styles from "./JoinPresentation.styles";

import SignupGoogle from "../Signup/SignupGoogle";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        djBooth1: file(relativePath: { eq: "join/djBooth1.jpg" }) {
          childImageSharp {
            fixed(width: 360, height: 360, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        person2: file(relativePath: { eq: "privatfest/person2.jpg" }) {
          childImageSharp {
            fixed(width: 80, height: 80, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={(data) => (
      <Styles.Container>
        <Styles.Columns>
          <Styles.ImageColumn>
            <Img
              fixed={data.djBooth1.childImageSharp.fixed}
              style={{ display: "block" }}
              alt="Platoon DJs på spelning"
              title="Platoon DJs på spelning"
            />
          </Styles.ImageColumn>
          <Styles.TextColumn>
            <Styles.H1>Vi är Platoon DJs</Styles.H1>
            <p>
              Älskar du musik eller bara vill umgås med trevliga människor? Om
              svaret är ja är Platoon DJs den perfekta föreningen för dig! Vi
              kräver inga förkunskaper när det kommer till tekniska delar och du
              behöver absolut inte ha någon tidigare erfarenhet för att gå med.
              Vi söker dig som har ett brinnande intresse för musik, är
              motiverad till att lära dig nya saker och framförallt tycker om
              att ha kul.
            </p>
            <p>
              Vi är en ideell studentförening på KTH som både brinner för musik
              och älskar att få folk att dansa. Vi spelar bland annat på flera
              av de största studentfesterna i Stockholm, klubbar och barer. De
              flesta av oss är studenter på KTH som driver föreningen genom att
              spela på olika event, engagera sig i olika poster och bidra till
              en bra gemenskap i gruppen.
            </p>
            <p>
              Vill du veta mera? Kom på vår workshop den 5:e september 18:00 i Tidningsrummet
            </p>
          </Styles.TextColumn>
        </Styles.Columns>
        <Styles.FullWideColumn>
          <SignupGoogle />
        </Styles.FullWideColumn>
      </Styles.Container>
    )}
  />
);
