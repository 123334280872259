import styled from "styled-components";
import { Color } from "../../../styles/";

export const Container = styled.div`
  max-width: 640px;
  margin: 72px auto 48px;

  color: rgba(0, 0, 0, 0.9);

  @media only screen and (max-width: 960px) {
    width: auto;
    margin: 72px auto 48px;
  }
`;

export const H1 = styled.h1`
  width: max-content;
  font-size: 3rem;
  font-weight: 600;

  @media only screen and (max-width: 960px) {
    width: auto;
    line-height: 130%;
  }
`;

export const P = styled.p`
  margin: 24px 0;
`;

export const Form = styled.form`
  > div {
    margin: 24px 0;
    box-sizing: border-box;

    @media only screen and (max-width: 500px) {
      flex: 1 1 100%;
      margin-bottom: 12px;
    }
  }
`;

export const ChapterSelect = styled.div`
  display: block;
  width: auto;
  margin-top: 8px !important;

  > select {
    width: 100%;
  }
`;

export const GrecaptchaTerms = styled.p`
  margin-top: 48px;
  font-size: 0.75rem;
  text-align: center;
`;

export const BottomRow = styled.div`
  display: flex;

  margin-top: 48px;
`;

export const ConsentContainer = styled.div`
  display: flex;
  align-items: center;
  > p {
    flex: 1 1 auto;
    margin: 0 24px 0 12px;

    font-size: 0.75rem;
    text-align: left;
  }
`;

export const ConsentCheckBox = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 36px;
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  border-radius: 4px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &.selected {
    background-color: ${Color.GREEN_PRIMARY};

    > img {
      opacity: 1;
    }
  }

  > img {
    width: 18px;
    height: 18px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
`;

export const SubmitButton = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-start;
  user-select: none;
  text-decoration: none;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 48px;
    padding: 0 48px;
    background-color: ${Color.GREEN_PRIMARY};
    border-radius: 24px;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
    color: ${Color.TEXT_WHITE_PRIMARY};

    &:hover {
      background-color: ${Color.GREEN_LIGHT};
      cursor: pointer;
    }
  }

  &.sending > span {
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 0.5);
  }

  &.sent > span {
    background-color: ${Color.GREEN_PRIMARY};
    &:hover {
      cursor: default;
    }
  }
`;

export const DateSelectContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media only screen and (max-width: 500px) {
    display: block;
  }

  > div {
    &:hover {
      cursor: pointer;
    }

    @media only screen and (max-width: 500px) {
      display: flex;
      align-items: center;
    }

    > div {
      box-sizing: border-box;
      width: 36px;
      height: 36px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin: 0px auto 12px auto;

      @media only screen and (max-width: 500px) {
        margin: 12px 24px 12px 12px;
      }

      border-radius: 4px;
    }

    &.selected > div {
      background-color: ${Color.ORANGE_PRIMARY};
    }

    > span {
      font-size: 0.875rem;
    }
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  &.enter {
    opacity: 0;
    display: flex;
  }

  &.enter-active {
    opacity: 1;
    display: flex;
    transition: opacity 300ms ease-in-out;
  }
  &.enter-done {
    opacity: 1;
    display: flex;
  }
  &.exit {
    opacity: 1;
    display: flex;
  }
  &.exit-active {
    opacity: 0;
    display: flex;
    transition: opacity 300ms ease-in-out;
  }
  &.exit-done {
    opacity: 0;
    display: none;
  }

  > div {
    box-sizing: border-box;
    width: auto;
    max-width: 400px;
    padding: 48px;
    margin: 24px;

    background-color: #f9f9f9;

    > h2 {
      width: max-content;
      font-size: 2rem;
      font-weight: 600;

      @media only screen and (max-width: 960px) {
        width: auto;
        line-height: 130%;
      }
    }

    > p {
      margin: 24px 0;
      line-height: 140%;
      text-align: left;
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 64px;
      height: 48px;
      padding: 0 48px;
      margin: 36px auto 0 auto;

      background-color: ${Color.GREEN_PRIMARY};
      border-radius: 24px;
      font-size: 0.875rem;
      text-transform: uppercase;
      font-weight: 600;
      color: ${Color.TEXT_WHITE_PRIMARY};

      &:hover {
        background-color: ${Color.GREEN_LIGHT};
        cursor: pointer;
      }
    }
  }
`;
